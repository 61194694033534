import Lang from "../images/Lang.svg"
import Nav from "../images/Nav.png"

// import Ai from "../images/Ai.svg"
import AiProctor from "../images/aiproctor2.png"

import Format from "../images/Format.svg"
// import Format from "../images/Format.png"
import Devices from "../images/Devices.png"
import Form from "../images/Form.png"
import Formmobile from "../images/Form-mobile.svg"
import Easy from "../images/Easy.svg"


export const SecondGrid = () => {
    return (
        <>
            <div className="grid md:grid-cols-8 gap-4 md:gap-10 grid-row-1 max-w-[1000px] mx-auto text-black w-[80%]  px-3 py-20">
                <div className="w-full h-full bg-[#EEEEEE] rounded-xl flex flex-col items-center justify-between p-4 md:p-10 overflow-hidden md:col-span-5">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-left text-[#A70000]  w-full">Native Language <br /> Support</h1>

                    <div className="pt-5 md:p-0 p-5">

                        <img src={Lang} alt="" />
                    </div>
                </div>
                <div className="w-full h-full bg-[#E3FCFF] text-[#00A99F]    rounded-xl s items-center p-4 md:p-10 md:col-span-3    overflow-hidden">
                    <h1 className="text-[1.5rem] md:text-4xl fosnt-bold text-left w-full">Quickly  <br /> Navigate</h1>

                    <img className="pt-20" src={Nav}  alt="" />
                    

                </div>
                <div className="w-full md:h-[495px] h-full bg-blue-green rounded-xl flex flex-col items-center justify-between p-4 md:p-10  md:col-span-4 gap-5 overflow-hidden">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-left text-[#fff] w-full">Ai <br /> Proctored</h1>
                    <div className="flex h-full flex-col justify-center ">

                        <img src={AiProctor} alt="" />
                    </div>  

                </div>
                <div className="w-full md:h-[495px] h-full bg-[#D9FFD8] rounded-xl flex flex-col justify-between p-4 md:p-10  md:col-span-4 overflow-hidden">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-left text-[#717171]">Autosave <br /> Answer</h1>
                    <div className="md:p-16 p-8">

                        <img className="md:h-[266px] md:w-[266px]" src={Format} alt="" />
                    </div>
                </div>


                <div className="w-full md:h-[495px] h-full bg-[#FFFEE9] rounded-xl flex flex-col gap-5 justify-between p-4 md:p-10  md:col-span-5 overflow-hidden">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-left text-[#096F00]">Operates on <br /> Multiple Device</h1>
                    <div className="p-10">

                        <img src={Devices} alt="" />
                    </div>
                </div>
                <div className="w-full h-full bg-pink-purple rounded-xl flex flex-col  gap-10 md:justify-between p-4 md:p-10  md:col-span-3 row-span-2 overflow-hidden">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-left text-[#FFF]">Answer  in <br/> multiple forms</h1>
                    {/* <div className="p-20"></div> */}
                    <div className="flex h-full flex-col justify-center" >

                        <img className="md:inline hidden" src={Form} alt="" />
                        <img className="md:hidden inline" src={Formmobile} alt="" />
                    </div>
                </div>
                <div className="w-full h-full md:h-[393px] bg-[#FFDDDD] rounded-xl flex flex-col justify-center p-4 md:p-10 gap-5 md:col-span-5 overflow-hidden">
                    <h1 className="text-[1.5rem] md:text-4xl font-bold text-left text-[#9A0066]">Hassel Free <br/> Experience</h1>
                    <div className="md:flex  md:h-full md:justify-center md:text-center md:p-0 p-6 ">

                        <img className="md:h-[235px]  md:w-[235px] h-[150px] w-[150px]" src={Easy} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}