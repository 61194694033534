// import logo from './logo.svg';
import Header from "./components/Header";
import Hero from "./components/Hero";
import "./App.css";
import Footer from "./components/Footer";
import ContactForm from "./components/ContactForm";
import { CalendlyPopupModal } from "./components/Calendly";
import { AiProctorDemo } from "./AiComponent/AiProctorDemo";
import { Annoucment } from "./components/Annoucmnet";
import { FirstGrid } from "./components/FirstGrid";
import { SecondGrid } from "./components/SecondGrid";
import { TestimonialSection } from "./components/TestimonialSec";
import { TeamSection } from "./components/TeamSection";
import { MentorsSection } from "./components/MentorsSection";
import { env } from "./utils/env";

// TODO not working

const hideTeamSection = env("REACT_APP_TEAM_SECTION_HIDE", "false") === "true";
const annoucmnetMessage = env("REACT_APP_ANNOUCMNET_MESSAGE", "Launching soon");
const annoucmnetLink = env("REACT_APP_ANNOUCMNET_LINK", "#");


function App() {
  return (
    <div className="App text-priText">
      <Header />
      <Hero />
      <div className="px-3">
        <Annoucment text={annoucmnetMessage} link={annoucmnetLink} />
      </div>

      <FirstGrid />
      {/* <AiProctorDemo /> */}
      <SecondGrid />
      <div className="px-3">
        <ContactForm />
      </div>
      {/* <TestimonialSection /> */}
      {hideTeamSection && <TeamSection />}
      {/* <MentorsSection /> */}
      <Footer />
      <CalendlyPopupModal />
    </div>
  );
}

export default App;
